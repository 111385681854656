import { QuizResolver } from './QuizResolver';
import { ComparisonChartResolver } from './ComparisonChartResolver';
import { ProductDemoResolver } from './ProductDemoResolver';
import { LandingPageResolver } from './LandingPageResolver';
import { ProductExperienceLandingPageResolver } from './ProductExperienceLandingPageResolver';
import { ComparisonChartV5Resolver } from './ComparisonChartV5';

export default {
  QuizResolver,
  ComparisonChartResolver,
  ProductDemoResolver,
  LandingPageResolver,
  ProductExperienceLandingPageResolver,
  ComparisonChartV5Resolver,
};
