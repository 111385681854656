export const EXPERIENCES_TYPES = {
  QUIZ: 'QUIZ',
  CHART: 'CHART',
  PRODUCT_DEMO: 'PRODUCT_DEMO',
  PRODUCT_DEMO_ANIMATION: 'PRODUCT_DEMO_ANIMATION',
  AR_PRODUCT_DEMO: 'AR_PRODUCT_DEMO',
  LANDING_PAGE: 'LANDING_PAGE',
  PRODUCT_EXPERIENCE_LANDING_PAGE: 'PRODUCT_EXPERIENCE_LANDING_PAGE',
  COMPARISON_CHART_V2: 'COMPARISON_CHART_V2',
};

export const EXPERIENCES_TYPE_MAP = {
  ProductDemo: EXPERIENCES_TYPES.PRODUCT_DEMO,
  ProductDemoAnimation: EXPERIENCES_TYPES.PRODUCT_DEMO_ANIMATION,
  QuizPartner: EXPERIENCES_TYPES.QUIZ,
  ComparisonChartPartner: EXPERIENCES_TYPES.CHART,
  ArProductDemo: EXPERIENCES_TYPES.AR_PRODUCT_DEMO,
  LandingPagePartner: EXPERIENCES_TYPES.LANDING_PAGE,
  ProductExperienceLandingPage:
    EXPERIENCES_TYPES.PRODUCT_EXPERIENCE_LANDING_PAGE,
  ComparisonChartV2: EXPERIENCES_TYPES.COMPARISON_CHART_V2,
};

// V2 was skipped in order to standardize all experiences versions and API to the same version number
export const VERSIONS = {
  v1: '1',
  v3: '3',
  v4: '4',
};
