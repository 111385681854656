import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { loadScript } from '../utils';

const BUILD_URL = process.env.REACT_APP_EMBED_PRODUCT_EXPERIENCE_LANDING_PAGE;

const ProductExperienceLandingPage = ({ uuid }) => {
  useEffect(() => {
    loadScript(BUILD_URL, 'main.js');
  }, []);

  return (
    uuid && (
      <Container>
        <div style={{ width: '100vw', height: '100vh' }}>
          <div
            id="irobot-embedded-pe-landing-page"
            data-experience-id={uuid}
            data-api-url={`${process.env.REACT_APP_API_BASE_URL}/v4`}
            data-build-url={BUILD_URL}
          />
        </div>
      </Container>
    )
  );
};

const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
`;

ProductExperienceLandingPage.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default ProductExperienceLandingPage;
