/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { EXPERIENCES_TYPES } from '../../constants';
import Resolvers from '../../resolvers';

// 16/8/22 BE always returns a hardcoded value as version. We cant trust on that
// value to know which resolver we should use.
const renderExperience = ({ entityType, entityUuid, version }) => {
  try {
    switch (entityType) {
      case EXPERIENCES_TYPES.QUIZ: {
        return <Resolvers.QuizResolver uuid={entityUuid} version={version} />;
      }

      case EXPERIENCES_TYPES.CHART: {
        return (
          <Resolvers.ComparisonChartResolver
            uuid={entityUuid}
            version={version}
          />
        );
      }

      case EXPERIENCES_TYPES.PRODUCT_DEMO_ANIMATION: {
        return <Resolvers.ProductDemoResolver uuid={entityUuid} version="3" />;
      }

      case EXPERIENCES_TYPES.PRODUCT_DEMO: {
        return <Resolvers.ProductDemoResolver uuid={entityUuid} version="1" />;
      }

      case EXPERIENCES_TYPES.AR_PRODUCT_DEMO: {
        return <Resolvers.ProductDemoResolver uuid={entityUuid} version="4" />;
      }

      case EXPERIENCES_TYPES.LANDING_PAGE: {
        return <Resolvers.LandingPageResolver uuid={entityUuid} />;
      }

      case EXPERIENCES_TYPES.PRODUCT_EXPERIENCE_LANDING_PAGE: {
        return (
          <Resolvers.ProductExperienceLandingPageResolver uuid={entityUuid} />
        );
      }

      case EXPERIENCES_TYPES.COMPARISON_CHART_V2: {
        return <Resolvers.ComparisonChartV5Resolver uuid={entityUuid} />;
      }

      default: {
        console.error(
          'Could not find matching experience resolver with type ',
          entityType
        );
        return null;
      }
    }
  } catch (e) {
    return null;
  }
};

export const ExperienceResolverV2 = ({ entityType, entityUuid, version }) =>
  entityType && <>{renderExperience({ entityType, entityUuid, version })}</>;

ExperienceResolverV2.propTypes = {
  entityType: PropTypes.string,
  entityUuid: PropTypes.string,
  version: PropTypes.string,
};

ExperienceResolverV2.defaultProps = {
  entityType: '',
  entityUuid: '',
  version: '',
};

export default ExperienceResolverV2;
