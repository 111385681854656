import React from 'react';
import PropTypes from 'prop-types';
import ComparisonChartV5 from '../components/ComparisonChartV5';

export const ComparisonChartV5Resolver = ({ uuid }) => (
  <ComparisonChartV5 uuid={uuid} />
);

ComparisonChartV5Resolver.propTypes = {
  uuid: PropTypes.string,
};

ComparisonChartV5Resolver.defaultProps = {
  uuid: '',
};

export default ComparisonChartV5Resolver;
