import React from 'react';
import PropTypes from 'prop-types';
import ProductExperienceLandingPage from '../components/ProductExperienceLandingPage';

export const ProductExperienceLandingPageResolver = ({ uuid }) => (
  <ProductExperienceLandingPage uuid={uuid} />
);

ProductExperienceLandingPageResolver.propTypes = {
  uuid: PropTypes.string,
};

ProductExperienceLandingPageResolver.defaultProps = {
  uuid: '',
};

export default ProductExperienceLandingPageResolver;
